import React, {Component} from 'react';
import './component.css'
import cat from '../backcat.png';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { ActionCreators } from '../actions'
import { Rate } from 'antd';
import UploadImage from './UploadImage';
const desc = ['terrible', 'too bad', 'bad', 'normal', 'good'];
const headStyle = {
  textAlign: "center",
  color: '#333',
  fontSize: 24,
  overflowX : 'auto',
  overflowY : 'auto',
};
class SuccessBanner extends Component {

   getRewardPrice(){
    var order_reward = this.props.order_info['reward']
    if (order_reward == null){
      return 10
    }else{
      return order_reward
    }
  }

  
  getReward(){
    if (this.props.order_info['star'] >= 4){

      if (this.props.order_info['benefit'] == 'Amazon Gift Card'){
        var final_reward = '$' + this.getRewardPrice() + ' '+ this.props.order_info['benefit']
      }else{
        var final_reward = this.props.order_info['benefit']
      }
      return (<div><label className="labela">Your reward:</label><p>{final_reward}</p></div>)
    }else{
      return (<div><label className="labela">Your Feedback:</label><p>{this.props.order_info['benefit']}</p></div>)
    }
  }
  render() {
    return (
      <div className="feebox">
        <div className="feedwrapper thankyou">
        <p className="thankyou">Thank you for you review !!</p>
        {this.props.order_info['star'] >= 4 ? <p className="thankyou">We will reply to you as soon as possible.</p> : <p className="thankyou">We will contact you within 48 hours to help you solve the problem. 
</p>}

        <ul className="resultblock">
          <li><label className="labela">Order Id:</label><p>{this.props.order_info['AmazonOrderId']}</p></li>

          <li><label className="labela">Usage Period:</label> <p>{this.props.order_info['period']}</p></li>
          <li><label className="labela">Your Rating:</label><Rate tooltips={desc} disabled={true}  value={this.props.order_info['star']} /></li>
          {this.props.order_info['star'] >= 4 ? <li><label className="labela">Your review screenshot:</label><UploadImage className="uploadimage" upload_allowed={false}/></li> : null}

          <li>{this.getReward()}</li>
        </ul>
          {this.props.order_info['star'] >= 4 ?
        <p className="bottomtextb">After you leave the review, you’ll receive an email from Amazon with a subject like “Thank you for reviewing … on Amazon” to let you know the review is alive (Sidenote: it may take 1-3 days for amazon to verify the review).
Please forward the confirmation email from Amazon to us directly as long as you receive it, with the note of your preference for a gift card or free product.  Then we’ll send you the benefit ASAP.
Please let me know if you have any questions or concerns, I'm always here for you.</p> : null}
        </div>
        {this.props.order_info['star']>=4 ? <div className="backimg forthanking"><img src={cat}/></div> : null}

      </div>
    )
  }
}
function mapStateToProps(state){
  return{
    order_info:state.order_info,
    step_info:state.step_info,
    user_info:state.user_info
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(SuccessBanner);
