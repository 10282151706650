import React, {Component} from 'react';
import { Input, Radio, Icon, Row, Button, Col,message,notification,Checkbox } from 'antd';
import Responsive from 'react-responsive-decorator';
import cat from '../backcat.png';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { ActionCreators } from '../actions'
import config from 'react-global-configuration'
class GetYourBenefit extends Component {

  constructor(props){
    super(props);
    this.state={
      benefit:"Amazon Gift Card",
      userName:this.props.user_info['userName'],
      email:this.props.user_info['email'],
      newsletter:true
    }
  }

  componentDidMount() {

  }

  check_url_tail(country){
    if ( country == 'CA') {
      return 'ca'
    }else if( country == 'UK'){
      return 'co.uk'
    }else{
      return 'com'
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleInformationSubmit(){
    var seller_id = this.props.order_info['seller_id']
    var asin = this.props.order_info['items'][0]['ASIN']
    var country = this.props.order_info['items'][0]['country']
    var url_country = this.check_url_tail(country)
    var order_id = this.props.order_info['AmazonOrderId']
    if (this.state.benefit !== ""){
      if (this.validateEmail(this.state.email)) {

        this.props.setUserInfo(this.state.userName,this.state.email)
        this.props.setBenefit(this.state.benefit)
        this.props.send_feedback()
      } else {
        notification['error']({
          message: 'Check your email!',
          description:
            <div>
              <p>Please check your email, the format is not right.</p>
            </div>
        });
      }
    } else {
      notification['error']({
        message: 'Notification Title',
        description:
          <div><p>Please check if you have fulfill the user name, email, and benefit method</p>
          </div>
      });
    }
  }

  getRewardPrice(){
    var order_reward = this.props.order_info['reward']
    if (order_reward == null){
      return 10
    }else{
      return order_reward
    }
  }

  handleUserNameChange = e =>{
    this.setState({
      userName: e.target.value,
    })
  }

  handleEmailChange = e =>{
    this.setState({
      email: e.target.value,
    })
  }

  handleBenefitMethod= e =>{
    this.setState({
      benefit: e.target.value,
    })
  }

  goback(){
    this.props.back()
  }

   handleNewsletterChange = e =>{
    this.setState({
      newsletter: !e.target.value,
    })
  }


  render() {

    return (

      <div className="feedbox">
      <div className="feedwrapper">
      <p class="questitle"><b>Please select your benefit.</b></p>

              <br/>
          <Radio.Group onChange={this.handleBenefitMethod} value={this.state.benefit}>
            <Radio value={"Same Free Product"}>
            Same Free Product
            </Radio>
            <Radio value={"Amazon Gift Card"}>
            ${this.getRewardPrice()}  Amazon Gift Card
            </Radio>
          </Radio.Group>
      </div>

      <div className="feedwrapper">
      
      <br/>
       
            <br/>
            <Button type="default" className="mydefaultbtn" onClick={this.goback.bind(this)}>Go Back </Button>
        <Button type="primary" className="mybtn" onClick={this.handleInformationSubmit.bind(this)}>Submit</Button>
         <div className="clear"></div>
         <div>
 <Checkbox style={{marginTop:"20px"}}  onChange={ this.handleNewsletterChange } defaultChecked={this.state.newsletter} >Join our Customers Club to Receive Newly Released Products, <br/>Amazon Gift Card & Lifetime Warranty.
</Checkbox>
 <br/>
 <br/>
 <p className="bottomtext bottomtextb"><strong>{config.get('source')}</strong> is the sole owner of the information collected from its customers. We will not sell or share this information with third parties in ways different from what is disclosed in our Privacy Policy.</p>
</div>
    </div>


       <div className="backimg"><img src={cat}/></div>
      </div>
    )
  }
}
function mapStateToProps(state){
  return{
    order_info:state.order_info,
    step_info:state.step_info,
    user_info:state.user_info

  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(GetYourBenefit);
