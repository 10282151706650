import React, { Component } from 'react';
import {
  Input,
  Button,
  Row,
  Col,
  message,
  notification,
  Radio,
  Icon,
} from 'antd';
import cat from '../backcat.png';
import './component.css'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { ActionCreators } from '../actions'
import Responsive from 'react-responsive-decorator';

class NegtiveReview extends Component {

  constructor(props){
    super(props);
    this.state={
      userName:this.props.user_info['userName'],
      email:this.props.user_info['email'],
      feedbackText:this.props.user_info['feedbackText']
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  goback(){
    this.props.back()
  }

  handleUserNameChange = e =>{
    this.setState({
      userName: e.target.value,
    })
  }

  handleEmailChange = e =>{
    this.setState({
      email: e.target.value,
    })
  }

  handleFeedbackTextChange = e => {
    this.setState({feedbackText: e.target.value})
  }

  submitFeedback(){
    if (this.state.feedbackText !== "") {
      if (this.validateEmail(this.state.email)) {
        var d = new Date();
        var today = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate();
        this.props.setUserInfo(this.state.userName,this.state.email)
        this.props.setBenefit(this.state.feedbackText)
        this.props.send_feedback()

      } else {
        notification['error']({
          message: 'Check your email!',
          description:
            <div>
              <p>Please check your email, the format is not right.</p>
            </div>
        });
      }
    } else {
      notification['error']({
        message: 'Please leave your feedback.',
        description:
          <div><p>Please tell us how to solve your problem and leave your name, email so we can contact you soon.</p>
          </div>
      });
    }
  }


  render() {
    const { TextArea } = Input;

    return (
      <div className="feedbox feedboxb">
      <div className="feedwrapper">
        <p class="questitle"><b> We're so sorry that our product doesn't satisfy your need.</b></p>
        <p style={{marginBottom:'10px',fontSize:"18px",paddingRight:'30px'}}>
         
          You're our valuable customer, we really take your feedback as precious. <br/>
          Please leave your question here, we will contact you to help asap within 48 hours.
        </p>


          <TextArea onChange = {
              value => this.handleFeedbackTextChange(value)
            }
            className="mytextarea"
            rows={2} placeholder="Please leave your feedback"/>
        </div>

       <div className="feedwrapper">
         
        
 <br/> <Button type="default" className="mydefaultbtn" onClick={this.goback.bind(this)}>Go Back </Button>
           <Button type="primary"  className="mybtn" onClick={this.submitFeedback.bind(this)}>Submit</Button>
          
        </div>




      </div>
    );
  }
}
function mapStateToProps(state){
  return{
    order_info:state.order_info,
    step_info:state.step_info,
    user_info:state.user_info

  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(NegtiveReview);
