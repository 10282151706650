import React, { Component } from 'react';
import UploadImage from './UploadImage';
import './component.css'
import {
  Button,
  Row,
  message,
  notification,
  Col,Carousel
} from 'antd';
import cat from '../backcat.png';

import review1 from '../slider/1.jpg';
import review2 from '../slider/2.jpg';
import review3 from '../slider/3.jpg';
import review4 from '../slider/4.jpg';
import review5 from '../slider/5.jpg';


import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { ActionCreators } from '../actions'
import { AmazonOutlined } from '@ant-design/icons';
const reviewCardStyle = {
  color : '#333',
  fontSize : 14,
  // paddingLeft: '30%',
  // paddingRight: '30%',
  margin: 'auto',
  width: '70%',
  overflowX : 'auto',
  overflowY : 'auto',
}



function check_url_tail(country){
  if ( country == 'CA') {
    return 'ca'
  }else if( country == 'UK'){
    return 'co.uk'
  }else{
    return 'com'
  }
}

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};


class PositiveReview extends Component {

  getRewardPrice(){
    var order_reward = this.props.order_info['reward']
    if (order_reward == null){
      return 10
    }else{
      return order_reward
    }
  }

  goback(){
    this.props.back()
  }



  submitReview(){
    if (this.props.order_info['imagelist'].length > 0) {

      message.success('Thank you for your feedback.')
      this.props.push('benefit')
    } else {
      notification['error']({
        message: 'Notification Title',
        description:
          <div><p>Please upload the screenshot of your review</p>
          </div>
      });
    }
  }

  gotoAmazonLink(){
    var country = 'com'
    if (this.props.order_info['AmazonOrderId'] != ''){

        var country = this.props.order_info['items'][0]['country']
        var country_suffix = check_url_tail(country)
        var asin = this.props.order_info['items'][0]['ASIN']
        window.open(
            `https://www.amazon.${country_suffix}/review/create-review/?asin=${asin}%3A5`,
            '_blank' // <- This is what makes it open in a new window.
          );
    }else{
      this.checkOrderId()
    }
  }



  render() {
    var country = this.props.order_info['items'][0]['country']
    var asin = this.props.order_info['items'][0]['ASIN']
    var order_id = this.props.order_info['AmazonOrderId']
    var url_country = check_url_tail(country)
    return (
      <div id='reviewb' className="feedbox">
      <div className="contentbox">
      <p className="questitle">A big thanks to you! We're thrilled that you're satisfied with our product.</p>
    
  <p className="reviewCardStyle"> If you wish, could you please share your review with more people on Amazon.  </p>
         <p className="reviewCardStyle">Afterward, you can choose to receive either a <strong>${this.getRewardPrice()} amazon gift card </strong> OR <strong>the same product</strong> (for free) </p>

        
         <p className="reviewCardStyle">Please kindly support our growing business by <strong>leaving us 5 stars</strong> to claim your reward!</p>
         <p className="reviewCardStyle"><b>MAKE SURE TO SAVE YOUR REVIEW SCREENSHOT AFTER LEAVING THE REVIEW, PLEASE RETURN HERE TO UPLOAD IT</b></p>

         <Button type="primary" style={{marginTop:"20px",marginBottom:"20px"}}  className="mybtn mybtna"  target="_blank" onClick={()=>this.gotoAmazonLink() }><AmazonOutlined /> <p>Leave A 5-Star Review</p>
</Button>
  </div>
  <div className="contentbox">
        <UploadImage className="uploadimage" upload_allowed={true} OrderId={order_id} ASIN={asin} handleReviewScreenShotSubmit={this.handleReviewScreenShotSubmit} OrderId={order_id}/>
    <p style={{fontSize:"14px",marginTop:'-10px'}}>maxiumn file size:1 MB</p>
            <Button type="default" className="mydefaultbtn" onClick={this.goback.bind(this)}>Go Back </Button>
            <Button type="primary" className="mybtn" onClick={this.submitReview.bind(this)}>Next</Button>
            

         </div>
         <div className="clear"></div>
         <div className="sliderimg">

          <Carousel autoplay autoplaySpeed={2000}>
    <div>
      <img src={review1}/>
    </div>
    <div>
      <img src={review2}/>
    </div>
    <div>
      <img src={review3}/>
    </div>
    <div>
      <img src={review4}/>
    </div>
    <div>
      <img src={review5}/>
    </div>
  </Carousel>


  </div>
      </div>
    );
  }
}
function mapStateToProps(state){
  return{
    order_info:state.order_info,
    step_info:state.step_info
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(PositiveReview);
